if (!localStorage.getItem('app.taskbar.items')) {
    localStorage.setItem('app.taskbar.items', JSON.stringify([]));
}
export default {
    state: {
        collapse: false,
        tasks: JSON.parse(localStorage.getItem('app.taskbar.items')),
        activeTaskId: null,
    },
    getters: {
        getCollapseTaskbar: (state):boolean => state.collapse,
        getTasks: (state):[] => state.tasks,
        getActiveTaskId: (state):string => state.activeTaskId
    },
    mutations: {
        setCollapseTaskbar: (state, payload:boolean) => state.collapse = payload,
        setTasks: (state, payload:[]) => state.tasks = payload,
        setActiveTaskId: (state, payload:string) => state.activeTaskId = payload,
    },
    actions: {},
    modules: {}
}
