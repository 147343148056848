import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'

const managerComponents = [
    {
        path: 'document-types',
        component: () => import('@/views/roles/admin/documentTypes/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/roles/admin/documentTypes/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/roles/admin/documentTypes/Form.vue')
            },
        ],
    },
    {
        path: 'users',
        component: () => import('@/views/users/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/users/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/users/Form.vue')
            },
        ],
    },
    {
        path: 'trailer-types',
        component: () => import('@/views/roles/admin/trailerTypes/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/roles/admin/trailerTypes/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/roles/admin/trailerTypes/Form.vue')
            },
        ],
    },
    {
        path: 'drivers',
        component: () => import('@/views/roles/admin/drivers/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/roles/admin/drivers/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/roles/admin/drivers/Form.vue')
            },
        ],
    },
    {
        path: 'trucks',
        component: () => import('@/views/roles/admin/trucks/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/roles/admin/trucks/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/roles/admin/trucks/Form.vue')
            },
        ],
    },
    {
        path: 'reasons',
        component: () => import('@/views/roles/admin/reasons/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/roles/admin/reasons/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/roles/admin/reasons/Form.vue')
            },
        ],
    },
    {
        path: 'transporters',
        component: () => import('@/views/roles/admin/transporters/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/roles/admin/companies/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/roles/admin/companies/Form.vue')
            },
        ],
    },
    {
        path: 'translations',
        component: () => import('@/views/roles/admin/translations/Index.vue'),
        children: []
    },
    {
        path: 'directions',
        component: () => import('@/views/roles/admin/directions/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/roles/admin/directions/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/roles/admin/directions/Form.vue')
            },
        ],
    },
    {
        path: 'trailers',
        component: () => import('@/views/roles/admin/trailers/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/roles/admin/trailers/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/roles/admin/trailers/Form.vue')
            },
        ],
    },
    {
        path: 'cargo-types',
        component: () => import('@/views/roles/admin/cargoTypes/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/roles/admin/cargoTypes/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/roles/admin/cargoTypes/Form.vue')
            },
        ],
    },
    {
        path: 'status-templates',
        component: () => import('@/views/roles/admin/statusTemplates/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/roles/admin/statusTemplates/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/roles/admin/statusTemplates/Form.vue')
            },
        ],
    },
    {
        path: 'report-problem-types',
        component: () => import('@/views/roles/admin/reportProblemTypes/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/roles/admin/reportProblemTypes/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/roles/admin/reportProblemTypes/Form.vue')
            },
        ],
    },
    {
        path: 'cargo-type-categories',
        component: () => import('@/views/roles/admin/cargoTypeCategories/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/roles/admin/cargoTypeCategories/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/roles/admin/cargoTypeCategories/Form.vue')
            },
        ],
    },
    {
        path: 'companies',
        component: () => import('@/views/roles/admin/companies/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/roles/admin/companies/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/roles/admin/companies/Form.vue')
            },
        ],
    },
    {
        path: 'currencies',
        component: () => import('@/views/roles/admin/currencies/Index.vue'),
        children: [
            {
                path: 'edit/:id',
                component: () => import('@/views/roles/admin/currencies/Form.vue')
            },
            {
                path: 'add',
                component: () => import('@/views/roles/admin/currencies/Form.vue')
            },
        ],
    },
    {
        path: 'orders',
        component: () => import('@/views/roles/admin/orders/Index.vue'),
        children: []
    },
];



const routes: Array<RouteRecordRaw> = [
    { path: "/:pathMatch(.*)", component: import('@/views/404/PageNotFound.vue') },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/Index.vue'),
        meta: {
            middleware: {
                redirectIfAuthorized: true,
            }
        }
    },
    {
        path: '/admin',
        component: () => import('@/views/roles/manager/app/Layout.vue'),
        children: [
            {
                path: '',
                name: 'kanban.admin',
                component: () => import('@/views/roles/manager/home/Index.vue'),
                children: [
                    {
                        name: 'kanban.admin.edit',
                        path: 'edit/:id',
                        component: () => import('@/views/roles/manager/home/KanbanCardItemEdit/KanbanCardItemEdit.vue')
                    },
                ],
                meta: {
                    keepAlive: true,
                    styles: {
                        search: true,
                        dynamicBarWidth: true,
                        filters: true,
                        sort: true
                    }
                }
            },
            ...managerComponents,
        ],
        meta: {
            middleware: {
                requiresAuth: true,
                requiresRole: 'admin',
            }
        }
    },
    {
        path: '/manager',
        component: () => import('@/views/roles/manager/app/Layout.vue'),
        children: [
            {
                path: '',
                name: 'kanban.manager',
                component: () => import('@/views/roles/manager/home/Index.vue'),
                children: [
                    {
                        name: 'kanban.manager.edit',
                        path: 'edit/:id',
                        component: () => import('@/views/roles/manager/home/KanbanCardItemEdit/KanbanCardItemEdit.vue')
                    },
                ],
                meta: {
                    keepAlive: true,
                    styles: {
                        search: true,
                        dynamicBarWidth: true,
                        filters: true,
                        sort: true
                    }
                }
            },
            ...managerComponents,
        ],
        meta: {
            middleware: {
                requiresAuth: true,
                requiresRole: 'manager',
                roleExceptions: ['admin'],
            }
        }
    },
    {
        path: '/dispatcher',
        component: () => import('@/views/roles/manager/app/Layout.vue'),
        children: [
            {
                path: '',
                name: 'kanban.dispatcher',
                component: () => import('@/views/roles/dispatcher/home/Index.vue'),
                children: [
                    {
                        path: 'edit/:id',
                        name: 'kanban.dispatcher.edit',
                        component: () => import('@/views/roles/dispatcher/home/KanbanCardItemEdit/KanbanCardItemEdit.vue'),
                    },
                ],
                meta: {
                    keepAlive: true,
                    styles: {
                        search: true,
                        filters: true,
                        sort: true
                    }
                }
            },
        ],
        meta: {
            middleware: {
                requiresAuth: true,
                requiresRole: 'dispatcher',
                roleExceptions: ['admin', 'manager'],
            }
        }
    },
    {
        path: '/broker',
        component: () => import('@/views/roles/broker/app/Layout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/views/roles/broker/home/Routes.vue'),

                meta: {
                    keepAlive: true,
                },

                children: [
                    {
                        path: 'verification',
                        component: () => import('@/views/roles/broker/home/Routes/Verification.vue'),
                        children: [
                            {
                                path: 'edit/:id',
                                component: () => import('@/views/roles/broker/home/Routes/verification/Edit.vue')
                            },
                        ]
                    },
                    {
                        path: 'on-route',
                        component: () => import('@/views/roles/broker/home/Routes/OnRoute.vue'),
                        children: [
                            {
                                path: 'edit/:id',
                                component: () => import('@/views/roles/broker/home/Routes/onroute/Edit.vue')
                            },
                        ]
                    },
                    {
                        path: 'in-work',
                        component: () => import('@/views/roles/broker/home/Routes/InWork.vue'),
                        children: [
                            {
                                path: 'edit/:id',
                                component: () => import('@/views/roles/broker/home/Routes/inwork/Edit.vue')
                            },
                        ]
                    },
                ],
            },
        ],
        meta: {
            middleware: {
                requiresAuth: true,
                requiresRole: 'broker',
                roleExceptions: ['admin'],
            }
        }
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router

