export default {
    state: {
        currencies: []
    },
    getters: {
        getCurrencies: (state) => state.currencies,
    },
    mutations: {
        setCurrencies: (state, payload) => state.currencies = payload
    },
    actions: {
    },
    modules: {
    }
}
