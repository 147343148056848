import user from "@/interfaces/user";

let userData = localStorage.getItem('app.user') || {};
if (typeof userData === 'string') {
    userData = JSON.parse(decodeURIComponent(escape(atob(userData))));
}
export default {
    state: {
        user: userData,
        appUsers: [],
        translations: {},
        languages: []
    },
    getters: {
        getUser: (state):object => state.user,
        getAppUsers: (state):[] => state.appUsers,
        getTranslations: (state) => state.translations,
        getLanguages: (state) => state.languages,
    },
    mutations: {
        setUser: (state, payload: user | object) => state.user = payload,
        setAppUsers: (state, payload: [] | object) => state.appUsers = payload,
        setTranslations: (state, payload: [] | object) => state.translations = payload,
        updateTranslation: (state, payload) => state.translations[payload.key] = payload.value,
        setLanguages: (state, payload) => state.languages = payload,
    },
    actions: {},
    modules: {}
}
