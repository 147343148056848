import app from './modules/app';
import manager from './modules/roles/manager';
import { createStore } from 'vuex';
import taskbar from "@/store/modules/taskbar";
import map from "@/store/modules/map";
import theme from "@/store/modules/theme";
export default createStore({
    modules: {
        app: app,
        manager: manager,
        taskbar: taskbar,
        map: map,
        theme: theme,
    },
});
