export default {
    state: {
        markers: [],
        directions: [],
        directionResponses: {},
        map: null,
        loadingMapInfo: false,
    },
    getters: {
        getMap: (state): object => state.map,
        getMarkers: (state): [] => state.markers,
        getDirections: (state): [] => state.directions,
        getLoadingMapInfo: (state): boolean => state.loadingMapInfo,
        getDirectionsResponses: (state): object => state.directionResponses,
    },
    mutations: {
        setMap: (state, payload: object) => state.map = payload,
        setMarkers: (state, payload: []) => state.markers = payload,
        setDirections: (state, payload: []) => state.directions = payload,
        setLoadingMapInfo: (state, payload: boolean) => state.loadingMapInfo = payload,
    },
    actions: {},
    modules: {}
}
