export default {
    state: {
        theme: false,
    },
    getters: {
        getAppTheme: (state):boolean => state.theme,
    },
    mutations: {
        setAppTheme: (state, payload: boolean) => state.theme = payload,
    },
    actions: {},
    modules: {}
}
